import ozon_icon from './img/ozon_icon.svg';
import ym_icon from './img/ym_icon.svg';
import customHeaders, { getSpace } from '../../common/headers';
import done_icon from './img/done_icon.svg';
import info_icon from './img/info_red.svg';
import review_tab from './img/review_tab.svg';
import question_tab from './img/question_tab.svg';
import banner_no_data from './img/banner-no-data.svg';
import { Rating } from '@mui/material';
import { ChatReview } from '../../components/ChatReview/ChatReview';
import { ModalReview } from '../../components/Modal/ModalReview/ModalReview';
import { TabsGreyBtn } from '../../components/TabsGreyBtn/TabsGreyBtn';
import { TooltipBlack } from '../../components/Tooltip/TooltipBlack/TooltipBlack';
import { NoDataContent } from '../../components/NoDataContent/NoDataContent'
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { ModalUnansweredFeedback } from '../../components/Modal/ModalUnansweredFeedback/ModalUnansweredFeedback'
import { ReactComponent as StarIcon } from './img/rating_star.svg';
import { useState, useEffect, useRef } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useModal from '../../store/modal';
import { useShallow } from 'zustand/react/shallow';
import {
  TableBox,
  RangeBox,
  Tabs,
  DropDownSelector,
  ButtonBasic,
  ViewSwitch,
  ModalBitrixForm,
  CheckboxToggleBlue,
} from '@lk-gtcom/ecomlab-components';
import {
  RavshanHttps,
  KonstantinMasterHttps,
  KonstantinMasterHttp,
} from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import {
  addFiltersByDataObj,
  addFiltersToUrlQuery,
  getDataObjByFilters,
  getFiltersByUrlQuery,
} from '../../common/utils';
import initTranslations from '../../i18n';
import './WorkWithReviews.scss';

const optionRating = (rating) => {
  return (
    <div className="rating-option">
      <span className="text_span">{rating}</span>
      <Rating
        precision={1}
        defaultValue={rating}
        max={1}
        readOnly
        emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
        icon={<StarIcon style={{ fill: '#faaf00' }} />}
        size="small"
      />
    </div>
  );
};

const ratingData = [
  {
    label: optionRating(1),
    value: 1,
  },
  {
    label: optionRating(2),
    value: 2,
  },
  {
    label: optionRating(3),
    value: 3,
  },
  {
    label: optionRating(4),
    value: 4,
  },
  {
    label: optionRating(5),
    value: 5,
  },
];

const options_prop = [
  {
    label: 'Ozon',
    value: 1,
  },
  {
    label: 'Wildberries',
    value: 3,
  },
  {
    label: 'Yandex',
    value: 2,
  },
];

const fetchFilterDataForReviews = async ({
  filterName,
  selectedSpace,
  page = 1,
  searchParam = '',
  selectedFilters = {},
  setFilterData,
  setSelectedFilter,
  append = false,
}) => {
  const url = `${KonstantinMasterHttps}/v3/filter/${filterName}`;

  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return null;
    }
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
  };

  const getFilterList = (filterArray) =>
    filterArray?.length > 0 ? filterArray.map((item) => item.value) : ['all'];

  const selectedIds =
    selectedFilters.selected_id && selectedFilters.selected_id.length > 0
      ? selectedFilters.selected_id.flatMap((id) =>
        typeof id === 'string' ? id.split(';') : id
      )
      : [];

  const payload = {
    search_param: searchParam || null,
    page,
    limit: 50,
    api_id_list: getFilterList(selectedFilters.api),
    category_id_list: getFilterList(selectedFilters.category),
    brand_id_list: getFilterList(selectedFilters.brand),
    mp_id_list: getFilterList(selectedFilters.marketplace),
    product_valuation_list: getFilterList(selectedFilters.rating),
    date_from: formatDate(selectedFilters.dateFrom),
    date_to: formatDate(selectedFilters.dateTo),
    selected_id_list: selectedIds || [],
  };

  const headers = {
    ...customHeaders,
    space: selectedSpace,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();

      const formattedData = data.filter_data?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setFilterData((prevData) =>
        append ? [...prevData, ...formattedData] : formattedData
      );

      if (data.selected?.length) {
        const selectedItems = data.selected.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setSelectedFilter(selectedItems);
      }
    } else {
      console.log(response.statusText);
    }
  } catch (error) {
    console.log(error);
  }
};

const namespaces = ['WorkWithReviews'];

const WorkWithReviews = ({ openConnectionModalById }) => {
  const {
    isModalActive,
    setIsModalActive,
    selectedServiceItemId,
    setSelectedServiceItemId,
  } = useModal(
    useShallow((state) => ({
      isModalActive: state.isModalActive,
      setIsModalActive: state.setIsModalActive,
      selectedServiceItemId: state.selectedServiceItemId,
      setSelectedServiceItemId: state.setSelectedServiceItemId,
    }))
  );

  const [i18n, seti18n, ready] = useState({}, { useSuspense: false });

  const translate = i18n?.t;
  const t = (key) => {
    if (typeof translate === 'function') {
      return translate(key);
    }
  };

  const initTranslation = async () => {
    const { i18n } = await initTranslations('en', namespaces);
    seti18n(i18n);
  };

  useEffect(() => {
    initTranslation();
  }, []);

  const btn_tab = [
    {
      label: t('reviews'),
      value: 'feedbacks',
      icon: review_tab,
    },
    {
      label: t('questions'),
      value: 'questions',
      icon: question_tab,
    },
  ];

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const path = useParams();
  const client_id = localStorage.getItem('client_id');
  const [isNotOneAccount, setIsNotOneAccount] = useState('done');

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('Table');
  const [showTabView, setShowTabView] = useState(true);
  const [pageValue, setPageValue] = useState('feedbacks');
  const [currentSort, setCurrentSort] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');
  const [sort, setSort] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [column_size, setColumnSize] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [counterData, setCounterData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const btns_answer = [
    {
      label: t('all'),
      count: counterData?.['all'],
      value: 'Все'
    },
    {
      label: t('answered'),
      count: counterData?.['answered'],
      value: 'Ответ отправлен',
    },
    {
      label: t('unanswered'),
      count: counterData?.['unanswered'],
      value: 'Ответ не отправлен',
    },
    {
      label: t('moderation'),
      count: counterData?.['moderation'],
      value: 'Модерация',
    },
  ];

  const btns = [
    {
      label: t('all'),
      count: counterData?.['all'],
      value: 'Все'
    },
    {
      label: t('answered'),
      count: counterData?.['answered'],
      value: 'Ответ отправлен',
    },
    {
      label: t('unanswered'),
      count: counterData?.['unanswered'],
      value: 'Ответ не отправлен',
    },
  ];

  const [isModal, setIsModal] = useState(false);
  const [questData, setQuestData] = useState({});
  const activeTab = useParams()['*'];
  const paginatorRef = useRef();
  const navigate = useNavigate();

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([
    { label: t('all'), value: 'all' },
  ]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedRating, setSelectedRating] = useState([
    { label: t('all'), value: 'all' },
  ]);
  const [selectedStatus, setSelectedStatus] = useState(t('all'));

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [allPeriod, setAllPeriod] = useState(false);
  const [dateFrom, dateTo] = date;

  const [isSearchLastPage, setIsSearchLastPage] = useState(false);
  const [chatPage, setChatPage] = useState(1);
  const [resetHeight, setResetHeight] = useState(false);
  const [totalCounter, setTotalCounter] = useState(0);
  const [isModalConnectOzon, setIsModalConnectOzon] = useState(false);
  const [isModalUnansweredFeedback, setIsModalUnansweredFeedback] =
    useState(false);

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [isAllUnanswered, setIsAllUnanswered] = useState(false);
  const [currentHref, setCurrentHref] = useState('');
  const [isCheckWithText, setIsCheckWithText] = useState(false);

  const [iniitalQuery, setInitialQuery] = useState(true);
  const [defaultDate, setDefaultDate] = useState('');

  useEffect(() => {
    if (iniitalQuery) {
      const dataObj = getFiltersByUrlQuery(searchParams);
      addFiltersByDataObj({
        dataObj,
        setSelectedMarketplace,
        setSelectedApi,
        setSelectedCategory,
        setSelectedBrand,
        setSelectedRating,
        setDate: setDefaultDate,
      });
      setInitialQuery(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (iniitalQuery) return;
    const dataObj = getDataObjByFilters({
      selectedMarketplace,
      selectedApi,
      selectedBrand,
      selectedCategory,
      selectedRating,
      date,
    });
    addFiltersToUrlQuery(dataObj, setSearchParams);
  }, [selectedApi, selectedBrand, selectedCategory, selectedRating, date]);

  function getQueryParamsAsObject(url) {
    const result = {};

    const queryString = url.split('?')[1];
    if (!queryString) return result;

    const queryParams = new URLSearchParams(queryString);

    queryParams.forEach((value, key) => {
      const formattedValue = value.includes(';') ? value.split(';') : [value];

      if (!result[key]) {
        result[key] = formattedValue;
      } else {
        result[key].push(...formattedValue);
      }
    });

    return result;
  }

  const currentUrl = window.location.href;
  const paramsObject = getQueryParamsAsObject(currentUrl);

  //логика для selected

  const mpParamRef = useRef([]);
  const categoryParamRef = useRef([]);
  const apiParamRef = useRef([]);
  const brandParamRef = useRef([]);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log(params.get('api_id')?.split(','));
    mpParamRef.current = params.get('mp_id')?.split(',') || [];
    categoryParamRef.current = params.get('category_id')?.split(',') || [];
    apiParamRef.current = params.get('api_id')?.split(',') || [];
    brandParamRef.current = params.get('brand_id')?.split(',') || [];

    if (mpParamRef.current.length > 0) {
      const mappedMarketplace = mpParamRef.current.map((mpId) =>
        options_prop.find((option) => String(option.value) === mpId)
      );
      setSelectedMarketplace(mappedMarketplace);
    }
  }, [location.search]);

  const fetchFilters = ({
    filterName,
    selectedFilters,
    setFilterData,
    setSelectedFilter,
    page = 1,
    searchParam = '',
    append = false,
  }) => {
    fetchFilterDataForReviews({
      filterName,
      selectedSpace: selectedSpace?.value,
      page,
      searchParam,
      selectedFilters,
      setFilterData,
      setSelectedFilter,
      append,
    });
  };

  const apiFilter = () => {
    fetchFilters({
      filterName: 'fq_api_ids',
      selectedFilters: {
        selected_id: apiParamRef.current,
        api: selectedApi,
        marketplace: selectedMarketplace,
        category: selectedCategory,
      },
      setFilterData: setApiData,
      setSelectedFilter: setSelectedApi,
      page: apiPage,
      searchParam: apiQuery,
      append: apiPage > 1,
    });
  };

  const categoryFilter = () => {
    fetchFilters({
      filterName:
        pageValue === 'feedbacks'
          ? 'feedbacks_categories'
          : 'questions_categories',
      selectedFilters: {
        selected_id: categoryParamRef.current,
        api: selectedApi,
        brand: selectedBrand,
        category: selectedCategory,
        marketplace: selectedMarketplace,
        dateFrom,
        dateTo,
      },
      setFilterData: setCategoryData,
      setSelectedFilter: setSelectedCategory,
      page: categoryPage,
      searchParam: categoryQuery,
      append: categoryPage > 1,
    });
  };

  const brandFilter = () => {
    fetchFilters({
      filterName:
        pageValue === 'feedbacks' ? 'feedbacks_brands' : 'questions_brands',
      selectedFilters: {
        selected_id: brandParamRef.current,
        api: selectedApi,
        brand: selectedBrand,
        category: selectedCategory,
        marketplace: selectedMarketplace,
        dateFrom,
        dateTo,
      },
      setFilterData: setBrandData,
      setSelectedFilter: setSelectedBrand,
      page: brandPage,
      searchParam: brandQuery,
      append: brandPage > 1,
    });
  };

  useEffect(() => {
    apiFilter();
  }, [apiPage, apiQuery, pageValue]);

  useEffect(() => {
    categoryFilter();
  }, [categoryPage, categoryQuery, pageValue]);

  useEffect(() => {
    brandFilter();
  }, [brandPage, brandQuery, pageValue]);

  useEffect(() => {
    setPageValue(activeTab);
    setCounterData({});
    // const category = searchParams.get('category');
    // if (category) {
    //   setSelectedCategory([{ label: category, value: category }]);
    // }

    // const brand = searchParams.get('brand');
    // if (brand) {
    //   setSelectedBrand([{ label: brand, value: brand }]);
    // }

    // const apiName = searchParams.get('apiName');
    // const apiId = searchParams.get('apiId');
    // if ((apiId?.length > 0) & (apiName?.length > 0)) {
    //   setSelectedApi([{ label: apiName, value: apiId }]);
    // }
  }, [activeTab]);

  useEffect(() => {
    if (path['*'] == 'archive') {
      setView('table');
      setShowTabView(false);
    } else {
      setShowTabView(true);
    }
  }, [path]);

  const fetchTableData = (params, resetPage = false, allUnswered = false) => {
    if (
      (selectedApi?.length > 0) &
      (selectedBrand?.length > 0) &
      (selectedCategory?.length > 0) &
      (selectedRating?.length > 0) &
      (selectedStatus?.length > 0)
    ) {
      let sort;
      let filtersParam = [];

      if (params) {
        [sort, filtersParam] = params;
      }

      setFetchedData([]);
      setLoading(true);
      let url;
      if (!window.location.hostname.match('localhost')) {
        if (allUnswered) {
          url = `${KonstantinMasterHttps}/wildberries/${pageValue}/table${sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
            }`;
        } else {
          url = `${KonstantinMasterHttps}/wb/${pageValue}/table?with_text=${isCheckWithText}${sort?.length > 0
            ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
            }`;
        }
      } else {
        if (allUnswered) {
          url = `${KonstantinMasterHttp}/feedbacks-and-questions/tables/unanswered-feedbacks-questions${sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
            }`;
        } else {
          url = `${KonstantinMasterHttp}/wb/${pageValue}/table?with_text=${isCheckWithText}${sort?.length > 0
            ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
            }`;
        }
      }

      let api_id_list;
      let brand_id_list;
      let category_id_list;
      let product_valuation_list;
      let status;
      let mp_id_list;
      const [date_from, date_to] = date;

      if (selectedApi?.length > 0) {
        api_id_list = selectedApi?.map((el) => el.value);
      }
      if (selectedBrand?.length > 0) {
        brand_id_list = selectedBrand?.map((el) => el && el !== '' && el.value);
      }
      if (selectedCategory?.length > 0) {
        category_id_list = selectedCategory?.map((el) => el.value);
      }
      if (selectedRating?.length > 0) {
        product_valuation_list = selectedRating?.map((el) => el.value);
      }
      if (selectedStatus) {
        status = selectedStatus;
      }
      if (selectedMarketplace?.length > 0) {
        mp_id_list = selectedMarketplace?.map((el) => el.value);
      }

      const body = JSON.stringify({
        page: paginatorRef.current?.page,
        limit: paginatorRef.current?.limit,
        filters: filtersParam,
        category_id_list,
        brand_id_list,
        api_id_list,
        mp_id_list,
        product_valuation_list,
        status,
        date_from: allPeriod
          ? null
          : new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0],
        date_to: allPeriod
          ? null
          : new Date(date_to)?.toISOString().split('T')?.[0],
      });

      setTableBody(body);

      if (view === 'chat') return;
      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const { data, labels, sort, column_size, total } = json;
          if (sort) {
            setSort(sort);
          }
          if (labels) setTableHeaders(labels);
          if (column_size) setColumnSize(column_size);
          if (total) paginatorRef.current?.setTotal(total);
          if (data) {
            if (data?.length === 0) setFetchedData([]);
            setFetchedData((prev) =>
              resetPage ? data : chatPage > 1 ? [...prev, ...data] : data
            );
          }
          setTableUrl(url);
          setTableBody(body);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const fetchCounter = (params) => {
    let sort;
    let filtersParam = [];

    filtersParam = [['Товар', 'textWithLink', '=', currentFilter]];

    if (params) {
      [sort, filtersParam] = params;
    }

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/counter?with_text=${isCheckWithText}`;
    } else {
      url = `${KonstantinMasterHttp}/wb/${pageValue}/counter?with_text=${isCheckWithText}`;
    }

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let product_valuation_list;
    let status;
    let mp_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }
    if (selectedRating?.length > 0) {
      product_valuation_list = selectedRating.map((el) => el?.value);
    }
    if (selectedStatus?.label) {
      status = selectedStatus?.label;
    }
    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    const page = paginatorRef.current?.page;
    const limit = paginatorRef.current?.limit;

    const body = JSON.stringify({
      page: page ? page : 1,
      limit: limit ? limit : 25,
      filters: filtersParam,
      category_id_list,
      brand_id_list,
      api_id_list,
      product_valuation_list,
      mp_id_list,
      status,
      date_from: allPeriod
        ? null
        : new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0],
      date_to: allPeriod
        ? null
        : new Date(date_to)?.toISOString().split('T')?.[0],
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setCounterData(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchConnectionStatus = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/service/check`;
    } else {
      url = `${RavshanHttps}/api/service/check`;
    }

    fetch(url, { method: 'GET', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then(({ status }) => {
        setIsNotOneAccount(status?.toLowerCase());
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchTotalCounter = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/feedbacks-and-questions/counters/unanswered`;
    } else {
      url = `${KonstantinMasterHttps}/feedbacks-and-questions/counters/unanswered`;
    }

    fetch(url, { method: 'GET', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then(({ feedbacks, questions }) => {
        setTotalCounter({ feedbacks, questions });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onActionHandle = (data) => {
    const {
      feedback_id,
      question_id,
      api_id,
      Оценка,
      Фото,
      Товар,
      Дата,
      Магазин,
      Отзыв,
      category_name,
      mp_id,
    } = data;
    const [productName, productLink] = Товар;
    const date = Дата;
    const brand = Магазин;
    const review = Отзыв;
    const status = data?.['Cтатус ответа'];
    const offer_id = productLink
      ?.split('/')
      ?.filter((el) => {
        const check = el?.replace(/[0-9]+/, '');
        if (!check) return el;
      })
      ?.join('');

    setQuestData({
      id: pageValue === 'feedbacks' ? feedback_id : question_id,
      mp_id,
      api_id,
      rating: Оценка,
      img: Фото,
      productName,
      category_name,
      offer_id,
      date,
      brand,
      review,
      status,
    });

    setIsModal(true);
  };

  useEffect(() => {
    setResetHeight(true);
    setChatPage(1);
    setFetchedData([]);
    fetchTableData([], true);
    fetchCounter();
  }, [
    selectedSpace,
    // selectedApi,
    // selectedMarketplace,
    // selectedBrand,
    currentSort,
    date,
    allPeriod,
    currentFilter,
    // selectedCategory,
    pageValue,
    selectedRating,
    selectedStatus,
    isAllUnanswered,
    isCheckWithText,
  ]);

  useEffect(() => {
    if (chatPage > 1) {
      fetchTableData([], true);
      fetchCounter();
    }
  }, [selectedSpace, chatPage]);

  useEffect(() => {
    if (view !== 'chat') {
      setCurrentSort([]);
      setCurrentFilter([]);
    }
  }, [view]);

  useEffect(() => {
    navigate(`feedbacks`);
    fetchTotalCounter();
  }, []);

  useEffect(() => {
    if (selectedRating?.length == ratingData?.length - 1) {
      setSelectedRating([{ label: 'Все', value: 'all' }]);
    }
  }, [selectedRating]);

  useEffect(() => {
    // fetchConnectionStatus()
  }, [isModalActive]);

  const formStubData = [
    {
      id: 'FIELDS[TITLE]',
      placeholder: 'Название лида',
      hidden: true,
      value: `Заявка на подключение отзывов ЯМ/OZON // Клиент ID ${client_id}`,
      inputType: 'text',
      type: 'input',
      required: true,
    },
    {
      id: 'FIELDS[NAME]',
      placeholder: 'Имя',
      inputType: 'text',
      type: 'input',
      required: true,
    },
    {
      id: 'FIELDS[TG]',
      placeholder: 'Способ связи',
      inputType: 'dropdown',
      type: 'dropdown',
      required: true,
      options_prop: [
        {
          label: 'Телефон',
          value: 'Телефон',
        },
        {
          label: 'Whatsapp',
          value: 'Whatsapp',
        },
        {
          label: 'Telegram',
          value: 'Telegram',
        },
      ],
    },
    {
      id: 'FIELDS[SOURCE_ID]',
      placeholder: 'выбирается из справочника источников в CRM',
      hidden: true,
      value: 15,
      type: 'input',
    },
    {
      id: 'FIELDS[ASSIGNED_BY_ID]',
      placeholder: 'ФИО ответственного',
      value: 11,
      hidden: true,
      type: 'input',
    },
    {
      id: 'FIELDS[PHONE][0][VALUE]',
      placeholder: 'Номер телефона',
      inputType: 'tel',
      type: 'input',
      required: true,
    },
    {
      id: 'FIELDS[COMMENTS]',
      placeholder: 'Комментарий',
      inputType: 'text',
      type: 'textarea',
    },
    // { id: 'FIELDS[SOURCE_ID]', placeholder: 'выбирается из справочника источников в CRM', hidden: true, value: 15, type: 'input' },
    // { id: 'FIELDS[SOURCE_DESCRIPTION]', placeholder: 'URL страницы', value: currentHref, hidden: true, type: 'input' },
    // { id: 'FIELDS[ASSIGNED_BY_ID]', placeholder: 'ФИО ответственного', value: 59, hidden: true, type: 'input' },
  ];

  useEffect(() => {
    setCurrentHref(window.location.href);
  }, []);

  useEffect(() => {
    setSelectedStatus('Все');
  }, [pageValue]);

  const formStubTitle = 'Хотите работать с отзывами на OZON?';
  const formStubSubTitle = 'Оставьте заявку и мы свяжемся в ближайшее время';
  const formStubBitrixToken = 'hxdxe0cpclw65c0j';

  const [isTableRequest, setIsTableRequest] = useState(false);

  useEffect(() => {
    if (isTableRequest) {
      fetchTableData();
      fetchCounter();
    }
    setIsTableRequest(false);
  }, [isTableRequest]);

  return (
    <>
      {isModalConnectOzon && (
        <ModalBitrixForm
          setIsModal={(e) => setIsModalConnectOzon(e)}
          inputArr={formStubData}
          formTitle={formStubTitle}
          bitrixToken={formStubBitrixToken}
          subTitle={formStubSubTitle}
          communication={true}
        />
      )}

      {isModalUnansweredFeedback && (
        <ModalUnansweredFeedback
          setIsModal={(e) => setIsModalUnansweredFeedback(e)}
          setIsAllUnanswered={(e) => setIsAllUnanswered(e)}
          tableBody={tableBody}
          totalCounter={totalCounter}
          date={allPeriod ? [new Date(0), new Date()] : date}
        />
      )}

      <div className="work-with-reviews">
        <div className="work-with-reviews__content">
          {isModal && (
            <ModalReview
              selectedStatus={selectedStatus}
              pageValue={pageValue}
              questData={questData}
              setQuestData={(e) => setQuestData(e)}
              setIsModal={(e) => setIsModal(e)}
            />
          )}

          <div className="title-and-view">
            <h1 className="title_main">
              {t('title')}
              <TooltipBlack
                text={
                  i18n?.language == 'en' ?
                    `Number of unanswered reviews ${totalCounter?.feedbacks} and questions ${totalCounter?.questions} for the entire period`
                    :
                    `Tüm dönem boyunca cevaplanmamış inceleme ${totalCounter?.feedbacks} ve soru sayısı ${totalCounter?.questions}`
                }
                top="-10px"
                left="105%"
                child={
                  <span
                    className="counter"
                    onClick={(e) => {
                      setIsModalUnansweredFeedback(true);
                      setIsAllUnanswered(true);
                    }}
                  >
                    {totalCounter?.feedbacks + totalCounter?.questions}{' '}
                    <img src={info_icon} />
                  </span>
                }
              />
            </h1>

            {/* <WidgetFeedback /> */}

            {pageValue === 'feedbacks' && (
              <button
                onClick={(e) => {
                  setIsModalActive(true);
                  setSelectedServiceItemId(1);
                  // setIsModalConnectOzon(true)
                }}
                className="btn-ozon"
              >
                <span>{t('add')}</span>
                <img src={ozon_icon} />
              </button>
            )}
          </div>

          <div className="toolbar-top">
            <FilterContainer
              onReset={(e) => {
                if (!loading) {
                  setSelectedApi([]);
                  setSelectedCategory([]);
                  setSelectedBrand([]);
                  setSelectedRating([]);
                }
              }}
            >
              <DropDownSelector
                style={{ maxWidth: '210px', minWidth: '210px' }}
                options_prop={options_prop}
                state={selectedMarketplace}
                setState={(e) => setSelectedMarketplace(e)}
                // disabled={isNotOneAccount !== 'done' || loading}
                placeholder={t('marketplace')}
                multi
                onBlur={() => {
                  apiFilter();
                  categoryFilter();
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />

              <DropDownSelector
                style={{ maxWidth: '210px', minWidth: '210px' }}
                options_prop={apiData}
                state={selectedApi}
                setState={(e) => setSelectedApi(e)}
                placeholder={t('shop')}
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
                defaultValue={[{ label: 'All', value: 'all' }]}
                multi
                onBlur={() => {
                  categoryFilter();
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />

              <DropDownSelector
                style={{ maxWidth: '210px', minWidth: '210px' }}
                options_prop={categoryData}
                state={selectedCategory}
                setState={(e) => setSelectedCategory(e)}
                placeholder={t('category')}
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
                defaultValue={[{ label: 'All', value: 'all' }]}
                multi
                onBlur={() => {
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />

              <DropDownSelector
                style={{ maxWidth: '210px', minWidth: '210px' }}
                options_prop={brandData}
                state={selectedBrand}
                setState={(e) => setSelectedBrand(e)}
                placeholder={t('brand')}
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
                defaultValue={[{ label: 'All', value: 'all' }]}
                multi
                onBlur={() => {
                  setIsTableRequest(true);
                }}
              />

              {pageValue === 'feedbacks' ? (
                <DropDownSelector
                  style={{ maxWidth: '210px', minWidth: '210px' }}
                  options_prop={ratingData}
                  state={selectedRating}
                  setState={(e) => setSelectedRating(e)}
                  placeholder={t('rating')}
                  // disabled={isNotOneAccount !== 'done' || loading}
                  // style={{ minWidth: '380px' }}
                  multi
                />
              ) : null}

              <label className="work-with-reviews__label">
                <CheckboxToggleBlue
                  value={isCheckWithText}
                  onChange={(e) => setIsCheckWithText(!isCheckWithText)}
                />
              </label>
              <span className="work-with-reviews__label-text">{t('with_text')}</span>
            </FilterContainer>

            {/* Табы */}
            <ViewSwitch
              disabled={isNotOneAccount !== 'done' || loading}
              selectView={view}
              tile={false}
              tileLong={false}
              chat
              setSelectView={(e) => setView(e)}
            />
          </div>

          <div className="toolbar-medium">
            <div className="tabs-and-rating">
              <Tabs
                query={location?.search}
                tabs={btn_tab}
                pageValue={pageValue}
                disabled={isNotOneAccount !== 'done' || loading}
              />
            </div>
            <RangeBox
              lang='en'
              defaultValue={defaultDate}
              disabled={isNotOneAccount !== 'done' || loading}
              setDate={(e) => setDate(e)}
              allPeriod={allPeriod}
              setAllPeriod={(e) => setAllPeriod(e)}
            />
          </div>

          {isNotOneAccount === 'waiting' ? (
            <NoDataContent
              img={banner_no_data}
              title={<h3>Подключение создано</h3>}
              description={
                <p style={{ textAlign: 'center' }}>
                  Сбор и обработка информации{' '}
                  <span style={{ fontWeight: '600', color: '#68727D' }}>
                    займет до 24 часов.
                  </span>{' '}
                  <br />
                  Пока мы настраиваем подключение, вы можете ознакомиться с
                  <a
                    style={{
                      display: 'block',
                      color: '#1890FF',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={(e) => {
                      window.open(
                        'https://wiki.ecomru.ru/main/article/Instruktsija_po_razdelu_%E2%80%9COtzyvy_i_voprosy%E2%80%9D_na_Ecomru.ru/735',
                        '_blank'
                      );
                    }}
                  >
                    инструкцией по разделу.
                  </a>
                </p>
              }
            />
          ) : isNotOneAccount === 'none' ? (
            <NoDataContent
              img={banner_no_data}
              title="Нет активных подключений"
              description="Создайте подключение чтобы пользоваться всеми инструментами сервиса"
              child={
                <ButtonBasic
                  green
                  onClick={(e) => {
                    openConnectionModalById([1, 3]);
                  }}
                  text="Новое подключение"
                  size="56px"
                />
              }
            />
          ) : (
            <>
              {view == 'Chat' && (
                <ChatReview
                  tableBody={tableBody}
                  btns={pageValue == 'feedbacks' ? btns_answer : btns}
                  pageValue={pageValue}
                  counterData={counterData}
                  isSearchLastPage={isSearchLastPage}
                  setStatus={(e) => setSelectedStatus(e)}
                  status={selectedStatus}
                  setCurrentFilter={(e) => setCurrentFilter(e)}
                  path={path['*']}
                  setPage={(e) => setChatPage(e)}
                  resetHeight={resetHeight}
                  setResetHeight={(e) => setResetHeight(e)}
                  selectedMarketplace={selectedMarketplace}
                  _date={date}
                  allPeriod={allPeriod}
                  isCheckWithText={isCheckWithText}
                />
              )}
            </>
          )}

          {isNotOneAccount !== 'none' && view == 'Table' && (
            <TableBox
              lang='en'
              tableUrl={tableUrl}
              tableBody={tableBody}
              loading={loading}
              fetchedData={fetchedData}
              headers={tableHeaders}
              columnSize={column_size}
              sort={sort}
              paginator={true}
              ref={paginatorRef}
              onAction={(e) => onActionHandle(e)}
              sortingFunc={(e) => fetchTableData(e)}
              fetchCallback={(e) => fetchTableData(e)}
              child={
                <TabsGreyBtn
                  btns={pageValue == 'feedbacks' ? btns_answer : btns}
                  currentBtn={selectedStatus}
                  setCurrentBtn={(el) => {
                    setSelectedStatus(el);
                  }}
                  checkValue
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export { WorkWithReviews };
