import customHeaders, { getSpace } from '../../common/headers';
import add_icon from './img/add_icon.svg';
import edit_btn from './img/edit_btn.svg';
import edit_icon from './img/edit_icon.svg';
import plus_icon from './img/plus_icon.svg';
import group_icon from './img/group_icon.svg';
import delete_icon from './img/trash_icon.svg';
import vector_icon from './img/vector_icon.svg';
import template_tab from './img/template_tab.svg';
import banner_no_data from './img/banner-no-data.svg';
import xls from './img/xls.svg';
import banner_template from './img/banner_template.svg';
import custom_scenarios_tab from './img/custom_scenarios_tab.svg';
import prepared_scenarios_tab from './img/prepared_scenarios_tab.svg';
import calendar_icon from './img/calendar_icon.svg';
import { useParams } from 'react-router-dom';
import { SendMessage } from '../SendMessage/SendMessage';
import { useDataTable } from '../../common/hooks';
import { NoDataContent } from '../NoDataContent/NoDataContent';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { ModalAddToGroup } from '../Modal/ModalAddToGroup/ModalAddToGroup';
import { ModalNewScenario } from '../Modal/ModalNewScenario/ModalNewScenario';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { ModalMassEditing } from '../Modal/ModalMassEditing/ModalMassEditing';
import { ModalScenarioSettings } from '../Modal/ModalScenarioSettings/ModalScenarioSettings';
import { ModalAddingReadyScenario } from '../Modal/ModalAddingReadyScenario/ModalAddingReadyScenario';
import { ReactComponent as ArrowDown } from './img/arrow_down.svg';
import { useState, useEffect, useRef } from 'react';
import { ModalConfirmationUnloadingl } from '../Modal/ModalConfirmationUnloading/ModalConfirmationUnloading';
import { ButtonActionDrop } from '../Buttons/ButtonActionDrop/ButtonActionDrop';
import { AnswerTemplates } from '../AnswerTemplates/AnswerTemplates';
import { ModalConfirmingTemplateDeletion } from './ModalConfirmingTemplateDeletion/ModalConfirmingTemplateDeletion';
import { ModalConfirmingGroupDeletion } from './ModalConfirmingGroupDeletion/ModalConfirmingGroupDeletion';
import {
  RavshanHttps,
  RavshanHttp,
  KonstantinFeedbackHttps,
  KonstantinFeedbackHttp,
} from '../../fetchUrls';
import {
  DropDownSelector,
  InputSearch,
  Tabs,
  TableBox,
  ButtonBasic,
  InputDynamicWidth,
  Checkbox,
  ViewSwitch,
} from '@lk-gtcom/ecomlab-components';
import {
  fetchApiReviewsFilter,
  fetchFeedbacksBrandFilter,
  fetchFeedbacksCategoryFilter,
} from '../../common/filtersFunc';
import { ModalDeleteTemplate } from '../Modal/ModalDeleteTemplate/ModalDeleteTemplate';
import useGeneralStore from '../../store/general';
import './AutomationResponses.scss';
import vi from 'date-fns/esm/locale/vi/index.js';

const tags_list = ['tag1', 'tag2', 'test-test'];

const btn_sort = [
  {
    label: (
      <span>
        A<br />Z
      </span>
    ),
    value: 'answer',
  },
  {
    label: 'Дата',
    value: 'created_at',
  },
];

const btn_sort2 = [
  {
    label: (
      <span>
        A<br />Z
      </span>
    ),
    value: 'group_name',
  },
  {
    label: 'Дата',
    value: 'created_at',
  },
];

const btn_tab_2 = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'В группе',
    value: 'in_groups',
  },
  {
    label: 'Вне группы',
    value: 'outside_groups',
  },
];

const btn_tab_3 = [
  {
    label: 'В группе',
    value: 'false',
    counter: 0,
  },
  {
    label: 'Вне группы',
    value: 'true',
  },
];

const btn_tab = [
  {
    label: 'Шаблоны ответов',
    value: 'answer_templates',
    icon: template_tab,
  },
  {
    label: 'Сценарии с ИИ',
    value: 'prepared_scenarios',
    icon: prepared_scenarios_tab,
  },
  {
    label: 'Ручные сценарии',
    value: 'custom_scenarios',
    icon: custom_scenarios_tab,
  },
  {
    label: 'Настройка'
  }
];

const varaibles = {
    '@brand': {
        ui: 'Бренд',
        value: '@brand'
    },
    '@category': {
        ui: 'Категория',
        value: '@category'
    },
    // '@seller': {
    //     ui: 'Продавец',
    //     value: '@@Seller'
    // },
    '@product': {
        ui: 'Товар',
        value: '@product_name'
    },
    '@mp_name': {
        ui: 'Название маркетплейса',
        value: '@mp_name'
    },
}
// Левая панель - шаблон

export const TemplateGroupItem = ({
  hideAll,
  created_at,
  title,
  id,
  editGroup,
  deleteGroup,
  selectGroup,
  isActiveGroup,
  setIsActiveGroup,
  isModalEditGroup,
  setIsModalEditGroup,
  editFunc,
  setSelectedGroupId,
  templates_count,
  updated_at,
  setIsModalDeleteGroup,
  isEditGroup,
  setIsEditGroup
}) => {

  const [textContent, setIsTextContent] = useState(title);

  const handleEdit = () => {
    setIsActiveGroup(id);

    if (id == isEditGroup) {
      setIsEditGroup(null);
    } else {
      setIsEditGroup(id);
    }

    if (id == isEditGroup) {
      editGroup(id, textContent);
    }
  };

  useEffect(() => {
    setIsTextContent(title);
  }, [title]);

  const editFunk = () => {
    handleEdit();
    setIsActiveGroup(id);

    if (editFunc) {
      editFunc(title);
      setSelectedGroupId(id);
    }
  };

  const btns = [
    {
      btn: 'Редактировать',
      func: (e) => editFunk(),
    },
    { btn: 'Удалить', func: (e) => setIsModalDeleteGroup(true) },
  ];


  return (
    <div
      className={
        isActiveGroup == id
          ? 'template-gropp-item_active'
          : 'template-gropp-item'
      }
      onClick={(e) => {
        setIsActiveGroup(id);
        selectGroup(title, id);
      }}
    >
      <div className="info-box">
        {isEditGroup === id ? (
          <input
            style={{
              border: '1px solid lightgrey',
              borderRadius: '4px',
              padding: '2px'
            }}
            className="changed-title"
            value={textContent}
            onChange={(e) => setIsTextContent(e.target.value)}
            placeholder="Введите название"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleEdit();
              }
            }}
          />
        ) : (
          <h4 className="title">
            {textContent ? textContent : 'Нет названия'}
          </h4>
        )}
        <div className="template-gropp-item__bottom-info">
          <div className="template_count">
            <img src={vector_icon} />
            <p className="text">{`шаблонов ${templates_count ? templates_count : '-'
              }`}</p>
          </div>

          {!hideAll && (
            <>
              {created_at && (
                <div className="template_count">
                  <img src={calendar_icon} />
                  <p className="text">{`Дата создания: ${new Date(
                    created_at
                  )?.toLocaleDateString('ru-RU')}`}</p>
                </div>
              )}

              {/* {updated_at && (
                <div className="template_count">
                  <img src={calendar_icon} />
                  <p className="text">{`Изменено: ${new Date(
                    updated_at
                  )?.toLocaleDateString('ru-RU')}`}</p>
                </div>
              )} */}
            </>
          )}
        </div>
      </div>

      {id != -1 && <ButtonActionDrop id={id} btns={btns} />}

      {/* {!hideAll && (
        <div className="btn-group">
          <TooltipLightBlue
            child={
              <button
                className="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
              >
                <img
                  style={{ width: 18, height: 18 }}
                  src={
                    isEdit
                      ? 'https://septik-komffort.ru/wp-content/uploads/2020/11/galochka_zel.png'
                      : edit_icon
                  }
                />
              </button>
            }
            text="Редактировать группу"
            top="calc(100% + 14px)"
            right="0px"
          />

          <TooltipLightBlue
            child={
              <button
                className="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteGroup(id);
                }}
              >
                <img src={delete_icon} />
              </button>
            }
            text="Удалить группу"
            top="calc(100% + 14px)"
            right="0px"
          />
        </div>
      )} */}
    </div>
  );
};
// Правая панель - элемент сообщения

export const MessageItem = ({
  created_at,
  updated_at,
  message,
  setValue,
  handleEdit,
  value,
  isEdit,
  view = 'Chat',
}) => {
  return (
    <div className="template-message__content">
      {/* <p className='text'>{message}</p> */}
      {isEdit && view == 'Table' ? (
        <input
          style={{ padding: '.5em .75em', borderRadius: '.5em' }}
          className="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Введите название"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleEdit();
            }
          }}
        />
      ) : (
        <p className="text">{message}</p>
      )}
      <div className="template-message__bottom-info">
        <p className="text_date">
          Создан {new Date(created_at)?.toLocaleDateString('ru-RU')}
        </p>
        <p className="text_date">
          Изменено {new Date(updated_at)?.toLocaleDateString('ru-RU')}
        </p>
      </div>
    </div>
  );
};

export const TemplateMessage = ({
  pageValue3,
  setAnswer,
  globalIsEdit,
  setGlobalIsEdit,
  showTransfer,
  group_id,
  id,
  setSelectedTemplateId,
  message,
  created_at,
  editTemplate,
  deleteTemplate,
  deleteTemplateInGroup,
  isActiveGroup,
  setIsModalAddToGroup,
  arrCheck,
  setArrCheck,
  selectedGroupId,
  setIsModalDeleteTemplate,
  setCurrentId,
  pageValue2,
  updated_at,
  view,
  setIsModalConfirmingTemplateDeletion
}) => {
  const [value, setValue] = useState(message);
  const [isEdit, setIsEdit] = useState(false);

  const btns = [
    {
      btn: 'Добавить в группу',
      func: () => {
        if (arrCheck.length < 1) {
          setArrCheck([id]);
        }
        setIsModalAddToGroup(true);
      },
    },
    { btn: 'Редактировать шаблон', func: () => handleEdit() },
    {
      btn: 'Удалить шаблон',
      func: () => {
        setSelectedTemplateId(id)
        if (selectedGroupId !== -1 && pageValue3 == 'false') {
          setIsModalConfirmingTemplateDeletion(true)
          // deleteTemplate(id, group_id);
        } else {
          setIsModalConfirmingTemplateDeletion(true)
          // deleteTemplateInGroup(id);
        }
      },
    },
  ];

  const handleEdit = () => {
    setIsEdit(!isEdit);
    setGlobalIsEdit((state) => !state);
    setAnswer(value);
    setSelectedTemplateId(id);
    if (globalIsEdit) {
      editTemplate(id, value, selectedGroupId);
    }
  };

  return (
    <div className="template-message">
      {isActiveGroup != 'Все' && (
        <Checkbox
          value={arrCheck?.includes(id)}
          onChange={(e) => {
            let arr = [];
            if (arrCheck?.includes(id)) {
              arr = arrCheck.filter((el) => el != id);
              setArrCheck(arr);
            } else {
              setArrCheck((prev) => [...prev, id]);
            }
          }}
        />
      )}
      <div className="template-message__item">
        <MessageItem
          created_at={created_at}
          updated_at={updated_at}
          message={message}
          setValue={(e) => setValue(e)}
          handleEdit={(e) => handleEdit(e)}
          value={value}
          view={view}
          isEdit={isEdit}
        />

        <ButtonActionDrop id={id} btns={btns} />

        {/* <div className="btn-group">
          <TooltipLightBlue
            child={
              <button
                className="btn-add"
                onClick={(e) => {
                  if (arrCheck.length < 1) {
                    setArrCheck([id]);
                  }
                  setIsModalAddToGroup(true);
                }}
              >
                <img src={plus_icon} />
              </button>
            }
            text="Добавить в группу"
            top="calc(100% + 14px)"
            right="0px"
          />

          {
            <>
              <TooltipLightBlue
                child={
                  <button
                    className="btn-edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit();
                    }}
                  >
                    <img
                      style={{ width: 18, height: 18 }}
                      src={
                        globalIsEdit
                          ? 'https://septik-komffort.ru/wp-content/uploads/2020/11/galochka_zel.png'
                          : edit_icon
                      }
                    />
                  </button>
                }
                text="Редактировать шаблон"
                top="calc(100% + 14px)"
                right="0px"
              />

              <TooltipLightBlue
                child={
                  <button
                    className="btn-delete"
                    onClick={(e) => {
                      if (pageValue3 == 'false' && selectedGroupId !== -1) {
                        deleteTemplate(id, group_id);
                      } else {
                        setCurrentId(id);
                        setIsModalDeleteTemplate(true);
                      }
                    }}
                  >
                    <img src={delete_icon} />
                  </button>
                }
                text="Удалить шаблон"
                top="calc(100% + 14px)"
                right="0px"
              />
            </>
          }
        </div> */}
      </div>
    </div>
  );
};

const AutomationResponses = ({ openConnectionModalById }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const activeTab = useParams()['*'];
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [pageValue2, setPageValue2] = useState(btn_tab_2[0].value);
  const [pageValue3, setPageValue3] = useState(btn_tab_3[0].value);

  const [answerValue, setAnswerValue] = useState('');
  const [answer, setAnswer] = useState('');

  const [selectedTemplateId, setSelectedTemplateId] = useState(-1);

  const [searchInput, setSearchInput] = useState('');
  const [templateInput, setTemplateInput] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isEditScenario, setIsEditScenario] = useState(false);

  const [isModal, setIsModal] = useState(false);
  const [isModalScenarioSettings, setIsModalScenarioSettings] = useState(false);
  const [isModalMassEditing, setIsModalMassEditing] = useState(false);

  const [groupList, setGroupList] = useState([]);
  const [groupTemplates, setGroupTemplates] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(-1);
  const [selectedGroup, setSelectedGroup] = useState({});

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState([
    { label: 'Ozon', value: 1 },
  ]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [isNotOneAccount, setIsNotOneAccount] = useState('done');

  const [currentSort, setCurrentSort] = useState(null);

  const [sort2, setSort2] = useState(null);
  const [currentSort2, setCurrentSort2] = useState(null);

  const [isActiveGroup, setIsActiveGroup] = useState(null);
  const [isModalConfirmationUnloadingl, setIsModalConfirmationUnloadingl] =
    useState(false);
  const [isModalDeleteTemplate, setIsModalDeleteTemplate] = useState(false);
  const [isModalDeleteGroup, setIsModalDeleteGroup] = useState(false);
  const [isModalAddToGroup, setIsModalAddToGroup] = useState(false);
  const [isModalAddingReadyScenario, setIsModalAddingReadyScenario] =
    useState(false);
  const [isEditGroup, setIsEditGroup] = useState(null);

  const [view, setView] = useState('Chat');

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [allPeriod, setAllPeriod] = useState(false);
  const [dateFrom, dateTo] = date;

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const { sort } = tableProps;

  const [scenarioData, setScenarioData] = useState({});
  const [selectedCategoryProperties, setSelectedCategoryProperties] = useState(
    {}
  );
  const [optionsGroup, setOptionsGroup] = useState([]);
  const [arrCheck, setArrCheck] = useState([]);
  const [currentId, setCurrentId] = useState(null);

  const [isModalEditGroup, setIsModalEditGroup] = useState(false);

  const [isOpenItemsUser, setIsOpenItemsUser] = useState(new Set());

  const fetchRuleTable = (params, pageValue = activeTab) => {
    let sort, filters;
    if (params) {
      [sort, filters] = params;
    }

    let sortQuery = `?order_param=${sort?.[0]}&order_direction=${sort?.[1]}`;
    let pathData =
      pageValue === 'custom_scenarios' ? 'front-rules' : 'category-rules';

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/${pathData}/table${sort ? sortQuery : ''
        }`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/${pathData}/table${sort ? sortQuery : ''
        }`;
    }

    const body = JSON.stringify({
      page: paginatorRef?.current?.page,
      limit: paginatorRef?.current?.limit,
      filters,
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchGroupList = () => {
    let url;
    let query = '';
    let queryArr = [];

    if (searchInput?.length > 0) {
      queryArr.push(`search_param=${searchInput}`);
    }
    if (pageValue2) {
      queryArr.push(`template_filter=${pageValue2}`);
    }
    if (currentSort) {
      queryArr.push(`order_param=${currentSort?.value}`);
    }
    if (sort) {
      queryArr.push(`order_direction=${sort == 'ASC' ? 'ASC' : 'DESC'}`);
    }
    if (queryArr?.length > 0) {
      query = `?${queryArr.join('&')}&limit=100&offset=0`;
    }

    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups${query}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups${query}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setGroupList(json);
        const optionList = json?.map((el) => {
          return {
            label: el.group_name,
            value: el.id,
          };
        });
        setOptionsGroup(optionList);
        if (selectedGroupId > -1) {
          selectGroup(selectedGroupId);
        } else {
          selectAllGroup(selectedGroupId);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createGroup = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups`;
    }

    const body = JSON.stringify({
      group_name: 'Новая группа',
    });

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        fetchGroupList();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const editGroup = (id, group_name) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups`;
    }

    const body = JSON.stringify({
      group_name,
      id,
    });

    fetch(url, { method: 'PUT', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setGroupList((prev) => prev.map((el) => (el?.id === id ? json : el)));
        fetchGroupList();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteGroup = (group_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups/${group_id}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups/${group_id}`;
    }

    fetch(url, { method: 'DELETE', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then(() => {
        fetchGroupList();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const selectAllGroup = (group_id) => {
    // setSelectedGroupId(-1);
    let query = '';
    let queryArr = [];

    if (templateInput?.length > 0) {
      queryArr.push(`search_param=${templateInput}`);
    }
    if (pageValue2) {
      queryArr.push(`template_filter=${pageValue2}`);
    }
    if (currentSort2) {
      queryArr.push(`order_param=${currentSort2?.value}`);
    }
    if (sort2) {
      queryArr.push(`order_direction=${sort2 ? sort2 : 'ASC'}`);
    }
    if (queryArr?.length > 0) {
      query = `?${queryArr.join('&')}`;
    }

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/templates${query}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/templates${query}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setGroupTemplates(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const selectGroup = (group_id) => {
    setSelectedGroupId(group_id);
    let query = '';
    let queryArr = [];

    if (templateInput?.length > 0) {
      queryArr.push(`search_param=${templateInput}`);
    }
    if (pageValue3) {
      queryArr.push(`out_of_group=${pageValue3}`);
    }
    if (currentSort2) {
      queryArr.push(`order_param=${currentSort2?.value}`);
    }
    if (sort2) {
      queryArr.push(`order_direction=${sort2 ? sort2 : 'ASC'}`);
    }
    if (queryArr?.length > 0) {
      query = `?${queryArr.join('&')}`;
    }

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups/${group_id}/templates${query}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups/${group_id}/templates${query}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setGroupTemplates(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createMassTemplate = (arr, selected_group) => {
    let url;
    let curr_group = selected_group ? selected_group : selectedGroupId

    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups/${curr_group}/templates`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups/${curr_group}/templates`;
    }

    const body = JSON.stringify(
      arr.split(/\n\n/).map((el) => ({ answer: el }))
    );

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then(() => {
        fetchGroupList()
        selectGroup(curr_group);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteTemplate = (template_id, group_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups/${group_id}/templates/${template_id}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups/${group_id}/templates/${template_id}`;
    }

    fetch(url, { method: 'DELETE', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then(() => {
        fetchGroupList();
        if (selectedGroupId > -1) {
          selectGroup(group_id);
        } else {
          selectAllGroup(group_id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteTemplateInGroup = (template_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/templates/${template_id}?confirmed_by_user=true`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/templates/${template_id}?confirmed_by_user=true`;
    }

    fetch(url, { method: 'DELETE', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then(() => {
        fetchGroupList();
        if (selectedGroupId > -1) {
          selectGroup(selectedGroupId);
        } else {
          selectAllGroup(selectedGroupId);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createTemplate = (arrTemplate) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/templates`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/templates`;
    }

    const current_array = arrTemplate.map((el) => {
      return {
        answer: el
      }
    })

    const body = JSON.stringify(current_array);

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then(() => {
        if (selectedGroupId > -1) {
          selectGroup(selectedGroupId);
        } else {
          selectAllGroup(selectedGroupId);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const editTemplate = (id, answer, group_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/templates`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/templates`;
    }

    const body = JSON.stringify({
      answer,
      id,
    });

    fetch(url, { method: 'PUT', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then(() => {
        if (selectedGroupId > -1) {
          selectGroup(selectedGroupId);
        } else {
          selectAllGroup(selectedGroupId);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addToGroupTemplate = (group_id = [], template_list) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups/${group_id}/templates/add`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups/${group_id}/templates/add`;
    }

    const body = JSON.stringify({
      template_id_list: template_list ? template_list : arrCheck,
    });

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setArrCheck = [];
        fetchGroupList();
        // if (selectedGroupId > -1) {
        //   selectGroup(selectedGroupId);
        // } else {
        //   selectAllGroup(selectedGroupId);
        // }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRule = (ruleData) => {
    setIsEditScenario(true);
    if (pageValue == 'prepared_scenarios') {
      setIsModalAddingReadyScenario(true);
    } else {
      setIsModal(true);
    }
    setScenarioData(ruleData);
  };

  const deleteScenario = (id) => {
    if (id) {
      // 'feedbacks/front-rules/'
      const string_parametr =
        pageValue == 'prepared_scenarios' ? 'category-rules' : 'front-rules';
      const url = `${KonstantinFeedbackHttps}/feedbacks/${string_parametr}/${id}`;

      fetch(url, { method: 'DELETE', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const text = await res.json();
            throw new Error(text?.detail);
          }
        })
        .then(() => {
          console.log('delete ' + id);
        })
        .catch((err) => console.error(err));
    }
  };

  const onDeleteAccountHandler = (e) => {
    deleteScenario(e?.id);
    setTimeout(() => {
      // let pathData = pageValue === 'custom_scenarios' ? 'front-rules' : 'category-rules';
      fetchRuleTable([], activeTab);
    }, 600);
  };

  const contextMenuArr = [
    { label: 'Удалить', onClick: onDeleteAccountHandler },
  ];

  const changeStatusScenario = (id, status) => {
    if (id) {
      const string_parametr =
        pageValue == 'prepared_scenarios' ? 'category-rules' : 'front-rules';
      const url = `${KonstantinFeedbackHttps}/feedbacks/${string_parametr}/${id}/status`;

      const body = JSON.stringify({
        active: status,
      });

      fetch(url, { method: 'PUT', headers, body })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onToggleStatus = (e) => {
    changeStatusScenario(e?.['Активно'][1], !e?.['Активно'][0]);
    setTimeout(() => {
      fetchRuleTable([], activeTab);
    }, 600);
  };

  useEffect(() => {
    fetchApiReviewsFilter({
      selectedMarketplace: selectedMarketplace,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: 'wb',
    });
  }, [selectedSpace, apiPage, apiQuery, selectedMarketplace]);

  useEffect(() => {
    fetchFeedbacksCategoryFilter({
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      allPeriod,
      selectedApi,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedCategory,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    allPeriod,
    selectedApi,
    pageValue,
    selectedBrand,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchFeedbacksBrandFilter({
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedApi,
      dateFrom,
      dateTo,
      allPeriod,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    pageValue,
    selectedBrand,
    brandPage,
    brandQuery,
  ]);

  useEffect(() => {
    setPageValue(activeTab);
    setArrCheck([]);
    fetchRuleTable([], activeTab);
  }, [activeTab]);

  useEffect(() => {
    setArrCheck([]);
  }, [pageValue2, pageValue3]);

  useEffect(() => {
    // fetchConnectionStatus()
    fetchGroupList();
  }, [searchInput, sort, currentSort, selectedSpace]);

  useEffect(() => {


    if (selectedGroupId > -1) {
      selectGroup(selectedGroupId);
    } else {
      selectAllGroup(selectedGroupId);
    }



  }, [
    selectedSpace,
    templateInput,
    selectedGroupId,
    pageValue3,
    pageValue2,
    isModalAddToGroup,
    sort2,
    currentSort2,
    pageValue3,
    searchInput,
    templateInput,
  ]);

  const sort_component = (current) => {
    let btn_sort_curr;
    let sort_curr;
    let setSort_curr;
    let setCurrentSort_curr;
    let btns_sort;

    if (current == 'group') {
      btns_sort = btn_sort2;
      btn_sort_curr = currentSort;
      sort_curr = sort;
      setSort_curr = setSort;
      setCurrentSort_curr = setCurrentSort;
    } else {
      btns_sort = btn_sort;
      btn_sort_curr = currentSort2;
      sort_curr = sort2;
      setSort_curr = setSort2;
      setCurrentSort_curr = setCurrentSort2;
    }

    return (
      <div className="tab-sort">
        {btns_sort.map((el) => (
          <button
            className={btn_sort_curr?.label == el.label ? 'btn_active' : 'btn'}
            key={el.label}
            onClick={(e) => {
              if (btn_sort_curr?.label == el.label) {
                setSort_curr(sort_curr == 'ASC' ? 'DESC' : 'ASC');
              } else setSort_curr('ASC');
              setCurrentSort_curr(el);
            }}
          >
            {el.label}
            <ArrowDown
              className={
                btn_sort_curr?.label == el?.label
                  ? sort_curr == 'ASC'
                    ? 'arrow'
                    : 'arrow_up'
                  : 'arrow'
              }
              style={
                btn_sort_curr?.label == el?.label
                  ? { stroke: 'white' }
                  : { stroke: '#64748B' }
              }
            />
          </button>
        ))}
      </div>
    );
  };

  const input_search = (current, dependence) => {
    return (
      <InputDynamicWidth
        position="left"
        maxWidth="100%"
        isOpen={true}
        onChange={(value) => {
          if (current == 'group') {
            setSearchInput(value);
          } else {
            setTemplateInput(value);
          }
        }}
        placeholder={current == 'group' ? 'Группы ответов' : 'Шаблоны ответов'}
        clearDependence={dependence ? [dependence] : false}
      />
    );
  };

  const tabs_curr = () => {
    return selectedGroupId === -1 ? (
      <Tabs
        tabs={btn_tab_2}
        stopRedirect
        pageValue={pageValue2}
        setPageValue={(e) => setPageValue2(e)}
      />
    ) : (
      <Tabs
        tabs={btn_tab_3}
        stopRedirect
        pageValue={pageValue3}
        setPageValue={(e) => setPageValue3(e)}
      />
    );
  };

  const group_list = (func) => {
    return groupList?.map(
      ({ group_name, id, created_at, templates_count, updated_at }) => (
        <TemplateGroupItem
          isActiveGroup={selectedGroupId}
          setIsActiveGroup={(e) => setSelectedGroupId(e)}
          key={id}
          title={group_name}
          id={id}
          created_at={created_at}
          editGroup={editGroup}
          setIsModalDeleteGroup={setIsModalDeleteGroup}
          deleteGroup={(e) => deleteGroup(e)}
          selectGroup={(title, id) => {
            setSelectedGroup({
              label: title,
              value: id,
            });
            setSelectedGroupId(id);
            // selectGroup(id)
          }}
          editFunc={func}
          isModalEditGroup={isModalEditGroup}
          setIsModalEditGroup={(e) => setIsModalEditGroup(e)}
          templates_count={templates_count}
          updated_at={updated_at}
          isEditGroup={isEditGroup}
          setIsEditGroup={setIsEditGroup}
        />
      )
    );
  };

  const [isModalConfirmingTemplateDeletion, setIsModalConfirmingTemplateDeletion] = useState(false)

  useEffect(() => {
    setGroupTemplates([])
    setSelectedGroupId(groupList.length > 0 ? groupList[0]?.id : -1)
  }, [view])


  return (
    <>
      {isModalDeleteTemplate && (
        <ModalDeleteTemplate
          deleteTemplateInGroup={(e) =>
            deleteTemplateInGroup(currentId, selectedGroupId)
          }
          setIsModal={(e) => setIsModalDeleteTemplate(e)}
        />
      )}

      {isModalDeleteGroup && <ModalConfirmingGroupDeletion
        setIsModal={(e) => setIsModalDeleteGroup(e)}
        deleteGroup={(e) => deleteGroup(selectedGroupId)}
      />}

      {isModalConfirmationUnloadingl && (
        <ModalConfirmationUnloadingl
          setIsModal={(e) => setIsModalConfirmationUnloadingl(e)}
        />
      )}

      {isModal && (
        <ModalNewScenario
          selectedApiProps={selectedApi}
          setScenarioData={(e) => setScenarioData(e)}
          scenarioData={scenarioData}
          isEdit={isEditScenario}
          setIsModal={(e) => setIsModal(e)}
          fetchRuleTable={fetchRuleTable}
          setIsEdit={setIsEditScenario}
        />
      )}

      {isModalScenarioSettings && (
        <ModalScenarioSettings
          setIsModal={(e) => setIsModalScenarioSettings(e)}
          selectedCategoryProperties={selectedCategoryProperties}
          setSelectedCategoryProperties={(e) =>
            setSelectedCategoryProperties(e)
          }
        />
      )}

      {isModalMassEditing && (
        <ModalMassEditing
          createMassTemplate={createMassTemplate}
          setIsModal={(e) => setIsModalMassEditing(e)}
          selectedGroup={selectedGroup}
          groupList={groupList}
          setSelectedGroupId={setSelectedGroupId}
          createTemplate={createTemplate}
          selectedGroupId={selectedGroupId}
        />
      )}

      {isModalAddToGroup && (
        <ModalAddToGroup
          selectedGroupId={selectedGroupId}
          optionsGroup={optionsGroup}
          addToGroupTemplate={(e) => addToGroupTemplate(e)}
          setArrCheck={(e) => setArrCheck(e)}
          setIsModal={(e) => setIsModalAddToGroup(e)}
        />
      )}

      {isModalAddingReadyScenario && (
        <ModalAddingReadyScenario
          setIsModalScenarioSettings={(e) => setIsModalScenarioSettings(e)}
          setIsModal={(e) => setIsModalAddingReadyScenario(e)}
          setScenarioData={(e) => setScenarioData(e)}
          scenarioData={scenarioData}
          setIsEdit={setIsEditScenario}
          isEdit={isEditScenario}
          fetchRuleTable={(e) => fetchRuleTable()}
          selectedCategoryProperties={selectedCategoryProperties}
          setSelectedCategoryProperties={(e) =>
            setSelectedCategoryProperties(e)
          }
          isOpenItemsUser={isOpenItemsUser}
          setIsOpenItemsUser={setIsOpenItemsUser}

        />
      )}

      {isModalConfirmingTemplateDeletion && <ModalConfirmingTemplateDeletion
        setIsModal={setIsModalConfirmingTemplateDeletion}
        deleteTemplateInGroup={deleteTemplateInGroup}
        deleteTemplate={deleteTemplate}
        selectedGroupId={selectedGroupId}
        selectedTemplateId={selectedTemplateId}
      />}

      <div className="automation-responses">
        <div className="content-1320">
          <h1 className="title_main">Автоматизация ответов</h1>
          {/* {pageValue != 'answer_templates' && <DropDownSelector
                    options_prop={apiData}
                    state={selectedShop}
                    setState={e => setSelectedShop(e)}
                    placeholder='Магазин'
                    disabled={isNotOneAccount}
                />} */}

          <div className="toolbar-medium">
            {<Tabs tabs={btn_tab} pageValue={pageValue} />}

            {/* Табы */}
            {/* {pageValue == 'answer_templates' && <ViewSwitch
              selectView={view}
              tile={false}
              tileLong={false}
              chat
              setSelectView={(e) => setView(e)}
            />} */}
          </div>
        </div>
        {isNotOneAccount === 'waiting' ? (
          <NoDataContent
            img={banner_no_data}
            title={<h3>Подключение создано</h3>}
            description={
              <p style={{ textAlign: 'center' }}>
                Сбор и обработка информации{' '}
                <span style={{ fontWeight: '600', color: '#68727D' }}>
                  займет до 24 часов.
                </span>{' '}
                <br />
                Пока мы настраиваем подключение, вы можете ознакомиться с
                инструкцией по разделу.
              </p>
            }
          />
        ) : isNotOneAccount === 'none' ? (
          <NoDataContent
            img={banner_no_data}
            title="Нет активных подключений"
            description="Создайте подключение чтобы пользоваться всеми инструментами сервиса"
            child={
              <ButtonBasic
                green
                onClick={(e) => {
                  openConnectionModalById([1, 3]);
                }}
                text="Новое подключение"
                size="56px"
              />
            }
          />
        ) : (
          <>
            {pageValue == 'answer_templates' && view == 'Chat' && (
              <div className="content-1320">
                <div className="chat-review">
                  <div className="chat-review__left-content">
                    <div className="chat-review__toolbar-top">
                      {input_search('group')}

                      {/* <InputSearch
                                            value={searchInput}
                                            onChange={e => setSearchInput(e)}
                                        /> */}
                    </div>

                    <div className="chat-review__toolbar-medium">
                      {sort_component('group')}

                      <ButtonBasic
                        whiteGreen
                        width="150px"
                        minWidth="150px"
                        text={`+ Новая группа`}
                        onClick={() => createGroup()}
                        style={{
                          color: '#00B45E'
                        }}
                      />
                    </div>

                    {/* Список шаблонов, левый контент*/}

                    <div className="template-content">
                      <div className="template-list">
                        {/* лист шаблонов */}
                        {/* <TemplateGroupItem
                          isActiveGroup={selectedGroupId}
                          setIsActiveGroup={(e) => setSelectedGroupId(e)}
                          key={'Все'}
                          title={'Все шаблоны'}
                          id={-1}
                          editGroup={editGroup}
                          deleteGroup={(e) => deleteGroup(e)}
                          selectGroup={(title, id) => {
                            setSelectedGroup({ label: title, value: -1 });
                            setSelectedGroupId(-1);
                            // selectAllGroup()
                          }}
                          hideAll
                          isModalEditGroup={isModalEditGroup}
                          setIsModalEditGroup={(e) => setIsModalEditGroup(e)}
                        /> */}
                        {groupList?.length > 0 ? (
                          <>{group_list()}</>
                        ) : (
                          <div className="info-block">
                            <h5 className="title">
                              Здесь пока нет шаблонов ответов
                            </h5>
                            <p className="text">
                              Создавайте группы и добавляйте в них шаблоны
                              ответов
                            </p>
                            <img
                              className="info-block__img"
                              src={banner_no_data}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="chat-review__right-content">
                    <div className="chat-review__toolbar-top">

                      {arrCheck.length > 0 ?
                        <div className='answer-templates__header-btn-group'>
                          <ButtonBasic
                            green
                            width="160px"
                            minWidth="160px"
                            text={`Скопировать ${arrCheck.length}`}
                            onClick={(e) => {
                              setIsModalAddToGroup(true)
                            }}
                          />

                          <ButtonBasic
                            red
                            width="160px"
                            minWidth="160px"
                            text={`Удалить ${arrCheck.length}`}
                            onClick={(e) => {
                              arrCheck.forEach((el) => {
                                deleteTemplateInGroup(el)
                              })
                              setArrCheck([])
                            }}
                          />

                          <ButtonBasic
                            grey
                            width="auto"
                            text={`Отмена`}
                            style={{
                              marginLeft: 'auto'
                            }}
                            onClick={(e) => setArrCheck([])}
                          />
                        </div>
                        :
                        <>
                          {input_search('message')}
                        </>
                      }

                      {/* {isActiveGroup != 'Все' && (
                        <button
                          onClick={(e) =>
                            setIsModalConfirmationUnloadingl(true)
                          }
                          className="btn"
                        >
                          <img src={xls} />
                          Выгрузить .xls
                        </button>
                      )} */}
                      {/* <InputSearch
                                            value={searchInput}
                                            onChange={e => setSearchInput(e)}
                                        /> */}
                    </div>

                    <div
                      className="chat-review__toolbar-medium"
                    >
                      {/* {tabs_curr()} */}

                      {sort_component('message')}


                      <ButtonBasic
                        whiteGreen
                        width="180px"
                        minWidth="180px"
                        text={'+ Массовая загрузка'}
                        onClick={() => setIsModalMassEditing(true)}
                        style={{
                          color: '#00B45E'
                        }}
                      //  disabled={selectedGroupId === -1}
                      />

                      {/* Если есть чекнутые появляется красная кнопка */}

                      {/* <ButtonBasic
                                                red
                                                width='180px'
                                                minWidth='180px'
                                                text={`- Удалить из группы`}
                                                onClick={(e) => (e)}
                                            /> */}
                    </div>

                    <div className="chat">
                      {groupTemplates?.length < 1 ? (
                        <div className="info-block">
                          <h5 className="title">
                            Здесь пока нет шаблонов ответов
                          </h5>
                          <p className="text">Создавайте шаблоны ответов</p>
                          <img
                            className="info-block__img"
                            src={banner_no_data}
                          />
                        </div>
                      ) : (
                        <div className="chat__content">
                          {groupTemplates?.map(
                            ({ answer, id, created_at, updated_at }) => (
                              <TemplateMessage
                                setCurrentId={(e) => setCurrentId(e)}
                                setIsModalDeleteTemplate={(e) => {
                                  setIsModalDeleteTemplate(e);
                                }}
                                selectedGroupId={selectedGroupId}
                                pageValue2={pageValue2}
                                pageValue3={pageValue3}
                                arrCheck={arrCheck}
                                setArrCheck={(e) => setArrCheck(e)}
                                isActiveGroup={isActiveGroup}
                                key={id}
                                id={id}
                                group_id={selectedGroupId}
                                message={answer}
                                created_at={created_at}
                                editTemplate={editTemplate}
                                setIsModalAddToGroup={(e) =>
                                  setIsModalAddToGroup(e)
                                }
                                showTransfer={pageValue3 !== 'in_group'}
                                globalIsEdit={isEdit}
                                setGlobalIsEdit={setIsEdit}
                                setAnswer={setAnswer}
                                setSelectedTemplateId={(e) => {
                                  setSelectedTemplateId(e);
                                }}
                                view={view}
                                updated_at={updated_at}
                                setIsModalConfirmingTemplateDeletion={(e) => setIsModalConfirmingTemplateDeletion(e)}
                              />
                            )
                          )}
                        </div>
                      )}

                      <SendMessage
                        // disabled={selectedGroupId === -1}
                        frequentAnswers
                        isEdit={isEdit}
                        setIsEdit={(e) => setIsEdit(e)}
                        message={answer}
                        actionBtnText={isEdit ? 'Сохранить' : 'Создать'}
                        value={answerValue}
                        onChange={(e) => setAnswerValue(e)}
                        onSendAction={(e) => {
                          if (isEdit) {
                            editTemplate(selectedTemplateId, answerValue);
                            setIsEdit(false);
                          } else {
                            if (selectedGroupId == -1) {
                              createTemplate([answerValue])
                            } else {
                              createMassTemplate(answerValue);
                            }
                          }
                          setAnswerValue('');
                        }}
                        showVariables={true}
                        varaibles={varaibles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pageValue == 'answer_templates' && view == 'Table') &&
              <AnswerTemplates
                groupList={groupList}
                selectedGroupId={selectedGroupId}
                setSelectedGroup={(e) => setSelectedGroup(e)}
                setSelectedGroupId={(e) => setSelectedGroupId(e)}
                editGroup={editGroup}
                deleteGroup={(e) => deleteGroup(e)}
                groupTemplates={groupTemplates}
                setCurrentId={setCurrentId}
                setIsModalDeleteTemplate={(e) => setIsModalDeleteTemplate(e)}
                deleteTemplateInGroup={deleteTemplateInGroup}
                arrCheck={arrCheck}
                setArrCheck={setArrCheck}
                deleteTemplate={deleteTemplate}
                editTemplate={editTemplate}
                setIsModalAddToGroup={(e) => setIsModalAddToGroup(e)}
                isEdit={isEdit}
                setIsEdit={(e) => setIsEdit(e)}
                setAnswer={(e) => setAnswer(e)}
                setSelectedTemplateId={(e) => setSelectedTemplateId(e)}
                sort_component={sort_component}
                input_search={input_search}
                tabs_curr={tabs_curr}
                createGroup={createGroup}
                answer={answer}
                createMassTemplate={createMassTemplate}
                addToGroupTemplate={addToGroupTemplate}
                pageValue3={pageValue3}
                group_list={group_list}
                setSearchInput={setSearchInput}
                setTemplateInput={setTemplateInput}
                fetchGroupList={fetchGroupList}
                setPageValue2={setPageValue2}
                setIsModalMassEditing={setIsModalMassEditing}
                createTemplate={createTemplate}
                setIsModalConfirmationUnloadingl={(e) => setIsModalConfirmationUnloadingl(e)}
                setIsModalConfirmingTemplateDeletion={(e) => setIsModalConfirmingTemplateDeletion(e)}
              />}

            {(pageValue == 'prepared_scenarios' ||
              pageValue == 'custom_scenarios') && (
                <>
                  <div className="content-1320">
                    <FilterContainer
                      onReset={(e) => {
                        setSelectedApi([]);
                        setSelectedCategory([]);
                        setSelectedBrand([]);
                      }}
                    >
                      <DropDownSelector
                        options_prop={apiData}
                        state={selectedApi}
                        setState={(e) => setSelectedApi(e)}
                        placeholder="Магазин"
                        multi={true}
                        fetchCallback={(e) => setApiPage(e)}
                        setInputChange={(e) => setApiQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                      />

                      <DropDownSelector
                        state={selectedCategory}
                        options_prop={categoryData}
                        setState={(e) => setSelectedCategory(e)}
                        placeholder="Категория"
                        multi={true}
                        fetchCallback={(e) => setCategoryPage(e)}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                      />

                      <DropDownSelector
                        state={selectedBrand}
                        options_prop={brandData}
                        setState={(e) => setSelectedBrand(e)}
                        placeholder="Бренд"
                        multi={true}
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                      />
                    </FilterContainer>
                  </div>


                  <TableBox
                    paginator={true}
                    ref={paginatorRef}
                    {...tableProps}
                    fetchCallback={(e) => fetchRuleTable(e)}
                    onAction={(e) => onEditRule(e)}
                    onToggle={(e) => onToggleStatus(e)}
                    contextMenuArr={contextMenuArr}
                    child={
                      <ButtonBasic
                        green
                        width="auto"
                        text="Добавить сценарий"
                        size="32px"
                        onClick={(e) => {
                          if (pageValue == 'prepared_scenarios') {
                            setIsModalAddingReadyScenario(true);
                          } else {
                            setIsEditScenario(false);
                            setIsModal(true);
                          }
                        }}
                      />
                    }
                  />

                </>
              )}
          </>
        )}
      </div>
    </>
  );
};

export { AutomationResponses };
